var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.domain))+" Logs ")]}),_c('div',{staticClass:"card-header-actions",staticStyle:{"margin-left":"auto"}},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"light"},on:{"click":function($event){$event.preventDefault();return _vm.runExport()}}},[_c('CIcon',{staticStyle:{"margin-top":"-2px"},attrs:{"name":"cil-storage"}}),_vm._v(" Start Export")],1),_c('CButton',{attrs:{"color":"light"},on:{"click":function($event){$event.preventDefault();return _vm.loadExportedLogs()}}},[_c('CIcon',{staticStyle:{"margin-top":"-2px"},attrs:{"name":"cil-sync"}}),_vm._v(" Reload")],1)],1)],2),_c('CCardBody',[_c('CDataTable',{attrs:{"itemsPerPage":15,"border":"","loading":_vm.isLoading,"items":_vm.normalizedLogsItems,"fields":_vm.columns},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('CElementCover',{attrs:{"boundaries":[
                    { sides: ['top'], query: 'td' },
                    { sides: ['bottom'], query: 'tbody' }
                  ]}},[_c('h1',{staticClass:"d-inline"},[_vm._v("Loading... ")]),_c('CSpinner',{attrs:{"size":"5xl","color":"info"}})],1)]},proxy:true},{key:"created_at",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created_at))])]}},{key:"action",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('a',{attrs:{"target":"_blank","href":item.url}},[_vm._v("Download")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }