import {Auth} from "aws-amplify";
import {AbstractService} from "@/services/AbstractService";

export default new class LogExportService extends AbstractService {

	async getAll(domain) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.get('/log-export',
			{
				params: {
					domain
				},
				headers: {
					Authorization: jwtToken
				}
			},
		)
	}

	async runExportToS3(domain) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.post('/log-export',
			{
				domain
			},
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}
}
