<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader style="display: flex; align-items: center">
            <slot name="header">
              <CIcon name="cil-grid"/> {{capitalizeFirstLetter(domain)}} Logs
            </slot>
            <div class="card-header-actions" style="margin-left: auto">
              <CButton class="mr-1" color="light" @click.prevent="runExport()"><CIcon style="margin-top: -2px;" name="cil-storage"/> Start Export</CButton>
              <CButton color="light" @click.prevent="loadExportedLogs()"><CIcon style="margin-top: -2px;" name="cil-sync"/> Reload</CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                :itemsPerPage="15"
                border
                :loading="isLoading"
                :items="normalizedLogsItems"
                :fields="columns">

              <template #loading>
                <CElementCover
                    :boundaries="[
                      { sides: ['top'], query: 'td' },
                      { sides: ['bottom'], query: 'tbody' }
                    ]"
                >
                  <h1 class="d-inline">Loading... </h1><CSpinner size="5xl" color="info"/>
                </CElementCover>
              </template>

              <template #created_at="{item}">
                <td>{{item.created_at}}</td>
              </template>

              <template #action="{item}">
                <td>
                  <a target="_blank" :href="item.url">Download</a>
                </td>
              </template>
            </CDataTable>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import dayjs from "dayjs";
import LogExportService from "@/services/LogExportService";

export default {
  name: 'LogsExport',
  components: {},
  data() {
    return {
      isLoading: false,
      columns: [
        {key: 'created_at', label: 'Time Of First Record In Log File'},
        {key: 'size', label: 'Size'},
        {key: 'action', label: 'Action'},
      ],
      exportedLogs: [],
      domain: null
    }
  },
  methods: {
    async loadExportedLogs() {
      this.isLoading = true;

      try {
        const response = await LogExportService.getAll(this.domain)

        this.exportedLogs = response.data;
      } finally {
        this.isLoading = false;
      }
    },
    async runExport() {
      this.isLoading = true;

      try {
        await LogExportService.runExportToS3(this.domain);

        this.$toast.success('The export process might take a few minutes. After it is finished, you will receive an email notification.');

      } finally {
        this.isLoading = false;
      }
    },
    formatBytes(bytes, decimals = 1) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  computed: {
    normalizedLogsItems() {
      return this.exportedLogs.map(el => {
        return {
          created_at: dayjs(el.at * 1000).toDate().toLocaleString(),
          size: this.formatBytes(el.size) || '',
          url: el.url || '',
        }
      });
    }
  },
  mounted() {
    if(this.$route.query.domain) {
      this.domain = this.$route.query.domain;
      this.loadExportedLogs();
    }
  }
}
</script>
